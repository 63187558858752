import * as React from 'react';
import { memo } from 'react';
import colors from '../../theme/colors';

interface Props {
  scaled?: boolean;
}

const AndroidBig: React.FC<Props> = ({ scaled = false, ...props }) => (
  <svg
    width={scaled ? '160vw' : 170}
    height={scaled ? '200.5vh' : 170}
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox={scaled ? '-140 -100 235 235' : '0 0 235 235'}
    xmlSpace="preserve"
    {...props}
  >
    <style />
    <switch>
      <g>
        <path
          fill={colors.additional.greyColor.TUNDORA}
          d="M145.16 31.82l8.75-14.58c1.42-2.38.65-5.47-1.72-6.89a5.043 5.043 0 00-3.81-.57c-1.3.33-2.39 1.14-3.08 2.29l-9.42 15.7c-12.03-4.05-24.67-4.05-36.69 0l-9.42-15.7a4.98 4.98 0 00-3.09-2.29c-1.3-.32-2.65-.12-3.8.57-2.38 1.42-3.15 4.51-1.72 6.89l8.75 14.58c-18.7 10.19-30.25 29.52-30.25 50.77 0 2.77 2.25 5.02 5.02 5.02h105.67c2.77 0 5.02-2.25 5.02-5.02 0-21.25-11.54-40.58-30.21-50.77zM69.97 77.56c2.53-24.1 23.16-42.79 47.55-42.79s45.02 18.69 47.55 42.79h-95.1zM170.35 95.9H64.68c-2.77 0-5.02 2.25-5.02 5.02v67.23c0 10.74 8.74 19.47 19.49 19.47h3.15v21.42c0 9 7.33 16.32 16.35 16.32s16.34-7.32 16.34-16.32v-21.42h5.05v21.42c0 9 7.33 16.32 16.35 16.32s16.34-7.32 16.34-16.32v-21.42h3.15c10.75 0 19.49-8.74 19.49-19.47v-67.23a5.03 5.03 0 00-5.02-5.02zm-27.67 86.71v26.44c0 3.46-2.83 6.27-6.3 6.27s-6.3-2.81-6.3-6.27v-26.44c0-2.77-2.25-5.02-5.02-5.02h-15.1c-2.77 0-5.02 2.25-5.02 5.02v26.44c0 3.46-2.83 6.27-6.3 6.27s-6.3-2.81-6.3-6.27v-26.44c0-2.77-2.25-5.02-5.02-5.02h-8.17c-5.21 0-9.45-4.23-9.45-9.43v-62.21h95.62v62.21c0 5.2-4.24 9.43-9.45 9.43h-8.17a5.03 5.03 0 00-5.02 5.02zM198.15 95.8c-7.97 0-14.46 6.49-14.46 14.47v43.92c0 7.98 6.49 14.47 14.46 14.47s14.46-6.49 14.46-14.47v-43.92c0-7.98-6.49-14.47-14.46-14.47zm4.41 58.39c0 2.44-1.98 4.43-4.41 4.43s-4.41-1.99-4.41-4.43v-43.92c0-2.44 1.98-4.43 4.41-4.43s4.41 1.99 4.41 4.43v43.92zM36.85 95.9c-7.97 0-14.46 6.49-14.46 14.47v43.92c0 7.98 6.49 14.47 14.46 14.47s14.46-6.49 14.46-14.47v-43.92c0-7.97-6.49-14.47-14.46-14.47zm4.41 58.39c0 2.44-1.98 4.43-4.41 4.43s-4.41-1.99-4.41-4.43v-43.92c0-2.44 1.98-4.43 4.41-4.43s4.41 1.99 4.41 4.43v43.92z"
        />
      </g>
    </switch>
  </svg>
);

export default memo(AndroidBig);
