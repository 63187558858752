import { graphql, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { mapObjIndexed } from 'ramda';

interface Images {
  tail: IGatsbyImageData;
  head: IGatsbyImageData;
  body: IGatsbyImageData;
}

const useStripe = () => {
  const data = useStaticQuery<Images>(graphql`
    {
      tail: file(relativePath: { eq: "stripe-tail.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 48
            height: 152
            placeholder: BLURRED
            layout: FIXED
          )
        }
      }
      head: file(relativePath: { eq: "stripe-head.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 57
            height: 152
            placeholder: BLURRED
            layout: FIXED
          )
        }
      }
      body: file(relativePath: { eq: "stripe-body.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 152
            quality: 100
            placeholder: BLURRED
            layout: FIXED
          )
        }
      }
    }
  `);

  return mapObjIndexed(getImage, data);
};

export default useStripe;
