import React, { memo } from 'react';

function Navigation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={36}
      height={36}
      viewBox="0 0 70 70"
      {...props}
    >
      <style />
      <switch>
        <g>
          <path d="M65.11 4.87a3.962 3.962 0 00-3.09-.94L47.8 5.83c-1.69.23-3.41.23-5.11.02l-15.24-1.9c-1.79-.22-3.6-.22-5.38.02L6.95 5.98a3.93 3.93 0 00-3.4 3.88v52.16c0 1.23.56 2.36 1.53 3.1.97.75 2.21.99 3.39.68l12.55-3.35c2.45-.65 5.04-.67 7.5-.06l12.87 3.22c1.6.4 3.23.6 4.86.6.88 0 1.78-.06 2.66-.18l14.12-1.88a3.93 3.93 0 003.4-3.88V7.81c.01-1.13-.47-2.2-1.32-2.94zM27.03 7.92l15.58 1.95c1.75.22 3.53.21 5.27-.02l13.3-1.77c.32-.04.64.05.88.27.24.21.38.51.38.84v50.18c0 .49-.36.9-.84.97l-12.72 1.7c-2.34.31-4.69.18-6.99-.39l-13.04-3.26c-1.3-.32-2.64-.49-3.98-.49-1.43 0-2.86.19-4.24.55L8.71 61.61c-.28.07-.57.02-.8-.16a.914.914 0 01-.36-.73V10.63c0-.4.3-.73.69-.79l14.26-1.9c1.51-.2 3.03-.21 4.53-.02z" />
          <path d="M33.53 54.79l.29-.27-.29.27a2.02 2.02 0 002.95 0c.53-.57 13.05-14.01 13.05-25 0-11.73-7.5-15.92-14.53-15.92s-14.53 4.18-14.53 15.92c0 11.05 12.52 24.44 13.06 25zm-9-25c0-10.71 7.32-11.86 10.47-11.86s10.47 1.16 10.47 11.86c0 6.85-6.18 15.36-9.87 19.83-.3.36-.91.36-1.2 0-3.69-4.46-9.87-12.97-9.87-19.83z" />
          <circle cx={35} cy={28.4} r={4.36} />
        </g>
      </switch>
    </svg>
  );
}

export default memo(Navigation);
