import React, { memo } from 'react';

function AI(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={36}
      height={36}
      viewBox="0 0 70 70"
      {...props}
    >
      <style />
      <switch>
        <g>
          <path d="M64.47 22.87c0-.99-.63-1.9-1.55-2.25-.26-.1-1.93-.5-2.75-.69-.27-.75-.55-1.43-.91-2.19l1.33-1.84c.7-.93.44-2.21-.21-2.86L56.54 9.2l-.09-.08c-.9-.68-1.97-.72-2.79-.11l-1.58 1.29c-.69-.34-1.41-.68-2.14-.91l-.41-2.27c-.21-1.05-1.08-1.76-2.18-1.76-.22 0-.98.05-1.58.69-.29.3-.61.85-.56 1.74l.46 3.47.02.1c.21.85.85 1.48 1.75 1.7 1.28.23 2.45.7 3.47 1.38l.11.07c.81.41 1.66.38 2.33-.06l1.41-.97 1.35 1.35-.98 1.42c-.45.68-.46 1.71-.03 2.37.51.92 1.17 2.29 1.44 3.59.21.85.85 1.48 1.73 1.7l1.82.36v1.78l-1.86.28c-.85.21-1.48.85-1.7 1.75-.23 1.27-.7 2.45-1.39 3.48-.46.69-.46 1.73.01 2.43l.97 1.41-1.35 1.35-1.42-.98c-.68-.45-1.71-.46-2.37-.03-1.1.61-2.31 1.09-3.61 1.44-1.12.3-1.79 1.48-1.49 2.62.29 1.08 1.44 1.95 3.42 1.43.96-.32 1.78-.65 2.68-1.08l1.84 1.33c.36.27.8.41 1.27.41.6 0 1.2-.24 1.6-.64l3.82-3.95.09-.1c.67-.89.72-1.97.14-2.74l-1.22-1.72c.36-.73.69-1.45.93-2.18l2.27-.41c1.05-.21 1.76-1.09 1.76-2.18l-.01-5.1z" />
          <path d="M50.13 52.09v-5.91c0-.52-.2-1.02-.6-1.41l-9.19-8.12c-.37-.36-.86-.56-1.38-.56h-1.14v-4.46l2.2-4.49h4.18a3.918 3.918 0 003.4 1.98c2.18 0 3.95-1.77 3.95-3.95s-1.77-3.95-3.95-3.95c-1.46 0-2.72.8-3.4 1.98h-5.81c-.35 0-.67.19-.84.5l-3.13 5.88c-.34.36-.54.85-.54 1.35v5.14h-2.11v-4.34c0-1.11-.02-6.84-.02-10.76 1.01-.6 1.72-1.62 1.89-2.83l9.13-5.03.11-.08c.37-.37.58-.86.58-1.39V3.53c0-1.08-.88-1.96-1.96-1.96h-4.06c-.09 0-.17.01-.26.02-6.9-.3-13.68 1.9-19.1 6.19-5.83 4.71-9.08 11.41-9.41 19.4v5.63c0 .28.06.56.18.82l1.07 2.33L5.73 47.1c-.36.96-.23 2.04.35 2.89a3.11 3.11 0 002.53 1.34h2.51l1.67 8.78v2.07a6.34 6.34 0 003.07 5.42c.93.56 2 .85 3.09.85 2.21 0 7.97-1.33 13.33-4.17 4.64-2.46 10.16-6.72 10.16-13.02v-2.12c0-1.08-.88-1.96-1.96-1.96s-1.96.88-1.96 1.96v2.12c0 4.35-4.44 7.62-8.16 9.6-4.71 2.5-9.59 3.67-11.41 3.67-.38 0-.76-.1-1.09-.3a2.4 2.4 0 01-1.15-2.05v-2.61c0-.17-.2-1.35-1.09-6.28-.33-1.86-.68-3.79-.7-3.92a1.96 1.96 0 00-1.96-1.97H9.81l4.07-10.82c.19-.49.17-1.03-.05-1.51l-1.23-2.69v-3.03h2.58v2.61c0 .3.07.6.21.88l3.05 6.09c.34.67 1.01 1.08 1.76 1.08h17.99l8.03 7v5.08a3.918 3.918 0 00-1.98 3.4c0 2.18 1.77 3.95 3.95 3.95s3.94-1.77 3.94-3.95a3.978 3.978 0 00-2-3.4zM32.38 14.67c-.7-.62-1.61-1.01-2.62-1.01-1.52 0-2.82.87-3.48 2.12H16.05c4.29-6.69 12.32-10.6 21.12-10.27.17.01.33 0 .41-.02h1.96v5.26l-7.16 3.92zM17.13 25.43h-4.4c.21-1.96.67-3.88 1.35-5.72h12.35c.36.57.87 1.04 1.47 1.36l-.04 15.02h-6.47L19.1 31.5v-4.11c0-1.08-.88-1.96-1.97-1.96z" />
        </g>
      </switch>
    </svg>
  );
}

export default memo(AI);
