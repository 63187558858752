import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { Tab } from '../../models/Tab';
import Text from '../Text';
import TabLink from './TabLink';

interface Props {
  tabs: Tab[];
  header?: string;
  description?: string;
  colorMode?: 'dark' | 'light';
  variant?: 'primary' | 'secondary';
  backgroundMode?: 'darkBG' | 'lightBG' | 'transparentBG';
}

const LinkOutreach: React.FC<Props> = ({
  tabs,
  header,
  description,
  colorMode = 'dark',
  variant,
  backgroundMode,
}) => {
  // make default value undefined, so that google cache shows all the options opened
  const [activeIndex, setActiveIndex] = useState<number>();

  useEffect(() => {
    setActiveIndex(0);
  }, []);

  const renderTabLink = useCallback<(item: Tab, index: number) => JSX.Element>(
    (item, index) => (
      <TabLink
        onChange={setActiveIndex}
        colorMode={colorMode}
        isActive={index === activeIndex}
        text={item.header}
        index={index}
        isLegacy={true}
      />
    ),
    [activeIndex, colorMode]
  );

  return (
    <>
      <div
        className={classNames('link-outreach-legacy', variant, backgroundMode)}
      >
        {header && (
          <Text
            type="h2"
            color={colorMode}
            weight="bold"
            align="center"
            className={'link-outreach-legacy__title'}
          >
            {header}
          </Text>
        )}
        {description && (
          <Text
            type="p"
            color={colorMode}
            className={'link-outreach-legacy__description'}
          >
            {description}
          </Text>
        )}
        <div className={'link-outreach-legacy__tabs'}>
          <div className={'link-outreach-legacy__tabs__list'}>
            {tabs?.map(renderTabLink)}
          </div>
        </div>
        <div className={'link-outreach-legacy__content'}>
          {tabs?.map((tab, index) => (
            <div
              className={classNames('link-outreach-legacy__content__list', {
                'link-outreach-legacy__content__list--active':
                  activeIndex === undefined ? true : index === activeIndex,
              })}
            >
              <div className={'link-outreach-legacy__content__list-item'}>
                {tab.title && (
                  <Text
                    type="h6"
                    color={colorMode}
                    weight="bold"
                    align="center"
                    className={'link-outreach-legacy__content__title'}
                  >
                    {tab.title}
                  </Text>
                )}

                {typeof tab.description === 'string' ? (
                  <Text
                    type="p"
                    color={colorMode}
                    className={'link-outreach-legacy__content__description'}
                  >
                    {tab.description}
                  </Text>
                ) : (
                  <div className={'link-outreach-legacy__content__description'}>
                    {tab.description}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default LinkOutreach;
