import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import { useMemo } from 'react';

interface Images {
  gfc: IGatsbyImageData;
  ri: IGatsbyImageData;
  rockaway: IGatsbyImageData;
  htg: IGatsbyImageData;
  rheingau: IGatsbyImageData;
  gfcDark: IGatsbyImageData;
  riDark: IGatsbyImageData;
  rockawayDark: IGatsbyImageData;
  htgDark: IGatsbyImageData;
  rheingauDark: IGatsbyImageData;
}

const COMPANIES_ALT = ['GFC', 'RI', 'Rockaway', 'HTG', 'Rheingau'];

const useCompaniesImages = () => {
  const data = useStaticQuery<Images>(graphql`
    {
      gfc: file(relativePath: { eq: "gfc.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 109
            height: 44
            placeholder: BLURRED
            layout: FIXED
          )
        }
      }
      ri: file(relativePath: { eq: "RI.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            height: 17
            placeholder: BLURRED
            layout: FIXED
          )
        }
      }
      rockaway: file(relativePath: { eq: "rockaway.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            height: 25
            placeholder: BLURRED
            layout: FIXED
          )
        }
      }
      htg: file(relativePath: { eq: "HTG.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 159
            height: 44
            placeholder: BLURRED
            layout: FIXED
          )
        }
      }
      rheingau: file(relativePath: { eq: "Rheingau.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            height: 27
            placeholder: BLURRED
            layout: FIXED
          )
        }
      }
      gfcDark: file(relativePath: { eq: "gfc-dark.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 109
            height: 44
            placeholder: BLURRED
            layout: FIXED
          )
        }
      }
      riDark: file(relativePath: { eq: "RI-dark.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            height: 17
            placeholder: BLURRED
            layout: FIXED
          )
        }
      }
      rockawayDark: file(relativePath: { eq: "rockaway-dark.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            height: 25
            placeholder: BLURRED
            layout: FIXED
          )
        }
      }
      htgDark: file(relativePath: { eq: "HTG-dark.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 159
            height: 44
            placeholder: BLURRED
            layout: FIXED
          )
        }
      }
      rheingauDark: file(relativePath: { eq: "Rheingau-dark.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            height: 27
            placeholder: BLURRED
            layout: FIXED
          )
        }
      }
    }
  `);

  const lightDarkImages = useMemo(
    () => ({
      light: [
        data.gfc,
        data.ri,
        data.rockaway,
        data.htg,
        data.rheingau,
      ].map((image, index) => (
        <GatsbyImage image={getImage(image)!} alt={COMPANIES_ALT[index]} />
      )),
      dark: [
        data.gfcDark,
        data.riDark,
        data.rockawayDark,
        data.htgDark,
        data.rheingauDark,
      ].map((image, index) => (
        <GatsbyImage image={getImage(image)!} alt={COMPANIES_ALT[index]} />
      )),
    }),
    [data]
  );

  return lightDarkImages;
};

export default useCompaniesImages;
