import classNames from 'classnames';
import marked from 'marked';
import React, { useCallback, useEffect, useState } from 'react';
import Text from '../Text';
export interface IQuestion {
  question: string;
  answer: string;
}

const Question: React.FC<IQuestion> = ({ question, answer }) => {
  // make default value undefined, so that google cache shows all the questions opened
  const [isOpened, setOpened] = useState<boolean>();

  useEffect(() => {
    setOpened(false);
  }, []);

  const onToggleQuestion = useCallback(() => {
    setOpened((prevOpened) => !prevOpened);
  }, []);

  return (
    <div className={'questions__accordion'}>
      <div
        className={classNames('questions__accordion-question', {
          'questions__accordion-question--active': isOpened ?? true,
        })}
        onClick={onToggleQuestion}
        role="button"
      >
        <Text
          className={'questions__accordion-question-text h6'}
          color="dark"
          weight="demi-bold"
          type="h3"
        >
          {question}
        </Text>
      </div>
      <div className={'questions__accordion-answer'}>
        <div
          className={'questions__accordion-answer-text'}
          dangerouslySetInnerHTML={{ __html: marked(answer) }}
        ></div>
      </div>
    </div>
  );
};

export default Question;
