import * as React from 'react';
import './style.scss';
import TrustedBy from '../TrustedBy';
import Text from '../Text';

interface Props {
  Icon: React.SFC<{ scaled?: boolean }>;
  title: string;
  subtitle: string;
  description: string;
}

const TechHeader: React.SFC<Props> = ({
  Icon,
  title,
  subtitle,
  description,
}) => {
  return (
    <div className={'tech-header'}>
      <div className={'tech-header__background-icon'}>
        <Icon scaled />
      </div>

      <div className={'tech-header__content'}>
        <div className={'tech-header__content__icon'}>
          <Icon />
        </div>
        <div className={'tech-header__content__text'}>
          <Text
            type="h1"
            color="dark"
            weight="bold"
            className={'tech-header__content__text__title h1'}
          >
            {title}
          </Text>
          <Text
            type="p"
            color="dark"
            weight="medium"
            className={'tech-header__content__text__subtitle h4'}
          >
            {subtitle}
          </Text>
          <Text
            type="p"
            color="dark"
            weight="medium"
            className={'tech-header__content__text__description h5'}
          >
            {description}
          </Text>
        </div>
      </div>

      <TrustedBy />
    </div>
  );
};

export default TechHeader;
