import * as React from 'react';
import { memo } from 'react';
import colors from '../../theme/colors';

interface Props {
  scaled?: boolean;
}

const IOSBig: React.FC<Props> = ({ scaled = false, ...props }) => (
  <svg
    width={scaled ? '160vw' : 164}
    height={scaled ? '200.5vh' : 110}
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox={scaled ? '-140 -100 235 235' : '0 0 235 235'}
    xmlSpace="preserve"
    {...props}
  >
    <style />
    <switch>
      <g>
        <path
          fill={colors.additional.greyColor.TUNDORA}
          d="M121.36 66.79c.14 0 .29 0 .43-.01 11.04-.54 21.7-6.47 30.83-17.14.01-.01.02-.02.02-.03 8.07-9.58 12.16-20.12 12.16-31.37 0-2.56-1.13-4.97-3.08-6.62a8.512 8.512 0 00-6.91-1.89c-4.38.75-8.99 2.3-13.73 4.61-6.31 3.12-11.61 7.1-15.73 11.83-8.2 9.28-12.91 20.84-12.91 31.76 0 2.4 1.01 4.73 2.77 6.4a8.953 8.953 0 006.15 2.46zm11.58-33.96c3.26-3.74 7.5-6.91 12.59-9.42 3.17-1.54 6.23-2.67 9.13-3.34-.4 8.19-3.6 15.74-9.73 23.03-6.9 8.06-14.61 12.7-22.36 13.51.36-8.09 4.08-16.67 10.37-23.78z"
        />
        <path
          fill={colors.additional.greyColor.TUNDORA}
          d="M203.98 162.11c-4.54-1.9-8.7-4.63-12.42-8.16-8.15-7.55-12.17-16.79-12.29-28.25-.13-14.67 6.41-25.8 19.99-34.02 1.2-.73 2.04-1.91 2.33-3.28a5.05 5.05 0 00-.81-3.94c-9.29-13.2-23.33-20.65-41.71-22.13-7.05-.6-15.21.82-25.02 4.35-11.67 4.28-14.57 4.86-15.16 4.94-.61-.03-3.41-.42-13.31-4.14-8.59-3.21-15.42-4.77-20.96-4.77-9.74.16-18.89 2.74-27.21 7.68-8.32 4.94-15.07 11.76-20.03 20.23-6.27 10.46-9.45 23.03-9.45 37.35 0 12.23 2.26 25.02 6.73 38.03 4.15 11.97 9.54 22.67 16.02 31.79 6.06 8.57 11.06 14.53 15.28 18.22 6.73 6.23 13.73 9.39 20.8 9.39.34 0 .68-.01 1.02-.02 4.66-.16 10.39-1.63 17.5-4.51 5.86-2.36 11.37-3.56 16.38-3.56 4.74 0 10.08 1.2 15.84 3.55 7.02 2.89 13.3 4.35 18.54 4.32 7.58-.18 14.68-3.25 21.11-9.15 3.95-3.44 8.75-9.21 14.67-17.66 4.34-6.19 8.14-13.01 11.31-20.33 1.26-3 2.5-6.23 3.66-9.61.88-2.53-.36-5.29-2.81-6.32zm-10.13 11.95c-2.88 6.65-6.34 12.87-10.29 18.5-5.4 7.69-9.8 13.03-13.08 15.88-.04.03-.07.06-.11.1-4.63 4.26-9.38 6.4-14.5 6.53-3.92-.01-8.81-1.21-14.53-3.56-7-2.85-13.61-4.3-19.67-4.3-6.31 0-13.1 1.44-20.16 4.29-5.92 2.39-10.65 3.67-14.11 3.78-4.75.2-9.53-2-14.61-6.73-.04-.04-.08-.07-.12-.11-3.57-3.11-8.19-8.66-13.74-16.51-5.93-8.34-10.88-18.18-14.71-29.23-4.1-11.94-6.18-23.63-6.18-34.74 0-12.47 2.7-23.29 8.03-32.2 4.12-7.02 9.67-12.64 16.5-16.69 6.88-4.08 14.14-6.13 22.13-6.26 4.17 0 10.01 1.39 17.34 4.13 11.22 4.21 14.86 4.78 16.94 4.78 1.64 0 4.57-.43 18.52-5.55 8.26-2.97 15.24-4.24 20.73-3.77 13.41 1.08 23.62 5.61 31.04 13.81-13.49 9.85-20.25 23.14-20.1 39.6.15 14.19 5.38 26.16 15.49 35.52a51.24 51.24 0 0011.07 8.03c-.62 1.63-1.25 3.21-1.88 4.7z"
        />
      </g>
    </switch>
  </svg>
);

export default memo(IOSBig);
