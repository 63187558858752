import classNames from 'classnames';
import * as React from 'react';
import ListOfIcons from '../ListOfIcons';
import Text from '../Text';
import './style.scss';
import useCompaniesImages from '../../hooks/useCompaniesImages';

interface Props {
  lightMode?: boolean;
}

const Companies: React.SFC<Props> = ({ lightMode = false }) => {
  const icons = useCompaniesImages();

  return (
    <div
      className={classNames('companies', {
        ['companies--light']: lightMode,
      })}
    >
      <Text
        type="h3"
        color={lightMode ? 'dark' : 'light'}
        weight="bold"
        uppercase
        align="center"
        className={'companies__title'}
      >
        Companies we’ve worked with
        <br />
        raised{' '}
        <div
          className={classNames('companies__title--marked', {
            ['companies__title--marked--light']: lightMode,
          })}
        >
          $150.000.000
        </div>{' '}
        in funding from such VC’s:
      </Text>
      <ListOfIcons
        icons={lightMode ? icons.dark : icons.light}
        className="companies__list"
      />
    </div>
  );
};

export default Companies;
