import React, { memo } from 'react';

function Payments(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={36}
      height={36}
      viewBox="0 0 70 70"
      {...props}
    >
      <style />
      <switch>
        <g>
          <path d="M9.59 54.37h11.92c1.08 0 1.97-.88 1.97-1.97 0-1.08-.88-1.97-1.97-1.97H9.59c-1.08 0-1.97.88-1.97 1.97a1.98 1.98 0 001.97 1.97z" />
          <path d="M68.24 25.26c-.5-.91-1.31-1.52-2.39-1.81L20.42 9.1c-1.68-.56-4.05-.2-5.19 2.69l-3.4 10.75H5.4c-2.28 0-4.13 1.85-4.13 4.13V57c0 2.28 1.85 4.13 4.13 4.13h46.58c1.75 0 3.32-1.14 3.9-2.77.11.02.19.02.25.02 1.82 0 3.4-1.16 3.94-2.89l8.49-26.86c.2-.65.37-2.1-.32-3.37zm-3.44 2.2l-8.49 26.86c-.01.03-.04.07-.1.1-.03.02-.08.02-.1.02l-.01-28.06c-.05-.8-.33-1.53-.78-2.13l9.38 2.96c.07.02.09.04.1.04.02.03.02.14 0 .21zm-14.91-4.93H15.94l3.02-9.56c.01-.04.05-.08.09-.1.05-.03.1-.03.16-.01l30.68 9.67zm2.29 4.14v2.37H5.2v-2.37c0-.11.09-.2.2-.2h46.58c.11 0 .2.09.2.2zm0 6.3v2.57H5.2v-2.57h46.98zm0 6.5V57a.2.2 0 01-.2.2H5.4a.2.2 0 01-.2-.2V39.47h46.98z" />
          <path d="M41.3 54.84c.95 0 1.81-.39 2.44-1.02.62.63 1.48 1.02 2.44 1.02 1.89 0 3.43-1.54 3.43-3.43s-1.54-3.43-3.43-3.43c-.95 0-1.81.39-2.44 1.02a3.405 3.405 0 00-2.44-1.02c-1.89 0-3.43 1.54-3.43 3.43s1.54 3.43 3.43 3.43z" />
        </g>
      </switch>
    </svg>
  );
}

export default memo(Payments);
