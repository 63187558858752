import * as React from 'react';
import Button from '../Button';
import TrustedBy from '../TrustedBy';
import Text from '../Text';
import './style.scss';

interface Props {
  title: string;
  subtitle: string;
}

const ServiceHeader: React.SFC<Props> = ({ title, subtitle }) => {
  return (
    <div className={'service-header'}>
      <div className={'service-header__content'}>
        <Text
          type="h1"
          color="dark"
          weight="bold"
          className={'service-header__content__text h3'}
        >
          {title}
        </Text>
        <Text
          type="label"
          color="dark"
          weight="bold"
          className={'service-header__content__text h3'}
        >
          {subtitle}
        </Text>
        <Button
          href="/contact-us/"
          size="medium"
          color="secondary"
          className={'service-header__content__button'}
        >
          Contact Us
        </Button>
      </div>

      <TrustedBy />
    </div>
  );
};

export default ServiceHeader;
