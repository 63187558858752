import React, { useCallback, useMemo } from 'react';
import Text from '../Text';
import Question, { IQuestion } from './Question';
import './style.scss';
import ServiceTitle from '../ServiceTitle';

interface Props {
  questions: IQuestion[];
  title?: string;
  subTitle?: string;
  multilane?: boolean;
  className?: string;
  isLegacy?: boolean;
}

const Questions: React.FC<Props> = ({
  questions = [],
  subTitle = 'Take a look at how we solve challenges to meet project requirements',
  title = 'Questions you may have',
  multilane = true,
  className,
  isLegacy = true,
}) => {
  const middleOfQuestions = Math.ceil(questions.length / 2);

  const leftQuestions = useMemo(
    () => questions.slice(0, middleOfQuestions),
    [middleOfQuestions, questions]
  );
  const rightQuestions = useMemo(
    () => questions.slice(middleOfQuestions),
    [middleOfQuestions, questions]
  );

  const renderQuestion = useCallback<(item: IQuestion) => JSX.Element>(
    (item) => <Question {...item} isLegacy={isLegacy} />,
    [isLegacy]
  );

  return (
    <section className={`questions ${className}`}>
      {isLegacy ? (
        <Text
          type="h2"
          color="dark"
          weight={isLegacy ? 'demi-bold' : 'bold'}
          className={'questions-legacy__title'}
          align="left"
        >
          {title}
        </Text>
      ) : (
        <ServiceTitle className={'questions__title'}>{title}</ServiceTitle>
      )}
      {isLegacy && (
        <Text
          type="p"
          color="dark"
          weight="medium"
          className={'questions-legacy__subtitle h6'}
          align="left"
        >
          {subTitle}
        </Text>
      )}

      {multilane ? (
        <div className={'questions__container'}>
          <div className="questions__col">
            {leftQuestions?.map(renderQuestion)}
          </div>
          <div className="questions__col">
            {rightQuestions?.map(renderQuestion)}
          </div>
        </div>
      ) : (
        <div className={'questions__container'}>
          <div className="questions__col_singleline">
            {questions?.map(renderQuestion)}
          </div>
        </div>
      )}
    </section>
  );
};

export default Questions;
