import classNames from 'classnames';
import React, { useCallback } from 'react';

interface Props {
  text: string;
  index: number;
  colorMode: string;
  onChange: (index: number) => void;
  isActive?: boolean;
  isLegacy: boolean;
}

const TabLink: React.FC<Props> = ({
  text,
  index,
  onChange,
  isActive,
  colorMode,
  isLegacy,
}) => {
  const onClick = useCallback(() => onChange(index), [index, onChange]);

  const classPrefix = isLegacy ? 'link-outreach-legacy' : 'link-outreach';

  return (
    <div
      className={classNames(
        `${classPrefix}__tabs__list-item`,
        `${classPrefix}__tabs__list-item--${colorMode}`,
        {
          [`${classPrefix}__tabs__list-item-active`]: isActive,
        }
      )}
      onClick={onClick}
      role="button"
    >
      <div className={`${classPrefix}__tabs__list-item-link`}>
        <span>{text}</span>
      </div>
    </div>
  );
};

export default TabLink;
