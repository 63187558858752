import * as React from "react"
import colors from "../../theme/colors"

interface Props {
  number: "1" | "2" | "3" | "4"
}

const Reward: React.SFC<Props> = ({ number }) => {
  let color = ""
  let numberSvg = undefined

  switch (number) {
    case "1":
      color = colors.main.accentColor.PRIMARY
      numberSvg = (
        <path
          d="M30.363 27.208l.06-.04-.106.096.046-.056zm.832 11.992V27.85l.01-.092-.112.138-.105.096-3.07 2.104-2.219-3.025 7.35-5.931h2.866V39.2h4.08v4.3h-12.97v-4.3h4.17z"
          fill={color}
        />
      )
      break

    case "2":
      color = colors.additional.blueColor.DODGER_BLUE_2
      numberSvg = (
        <path
          d="M33.41 39.708l.46-.088h6.26v4.3H26.5v-2.711l.14-.145a69.308 69.308 0 001.608-1.743 58.448 58.448 0 001.8-2.111 52.306 52.306 0 001.767-2.303c.57-.786 1.08-1.575 1.53-2.369.446-.785.799-1.544 1.059-2.275.252-.709.376-1.373.376-1.993 0-.702-.194-1.259-.58-1.693-.363-.41-.972-.627-1.86-.627a4.74 4.74 0 00-1.778.369c-.617.248-1.13.562-1.545.94l-.463.423-2.076-3.672.325-.267a10.335 10.335 0 012.789-1.623c1.052-.415 2.278-.62 3.678-.62.912 0 1.755.128 2.528.386.783.26 1.462.644 2.033 1.15a5.34 5.34 0 011.358 1.9c.328.756.491 1.629.491 2.614a9.673 9.673 0 01-.584 3.258 19.608 19.608 0 01-1.447 3.14c-.58 1.017-1.22 1.999-1.922 2.945a51.756 51.756 0 01-2.045 2.59l-.076.072-.197.153z"
          fill={color}
        />
      )
      break

    case "3":
      color = colors.main.accentColor.SECONDARY
      numberSvg = (
        <path
          d="M34.127 30.424l.081-.05-.139.127.058-.077zm-1.161-3.701l-.686.077h-5.54v-4.3H39.2v2.796l-4.33 5.803-.028.026c.61.084 1.194.242 1.754.473.694.286 1.302.7 1.82 1.24.517.54.927 1.201 1.23 1.98.304.782.454 1.673.454 2.672 0 1.248-.208 2.338-.63 3.267a6.531 6.531 0 01-1.753 2.336 7.267 7.267 0 01-2.591 1.385c-.969.295-2.008.442-3.116.442-.909 0-1.847-.082-2.814-.247-.987-.168-1.795-.413-2.426-.739l-.376-.195 1.36-4.196.52.26a8.058 8.058 0 001.624.592c.588.15 1.252.225 1.992.225 1.115 0 1.931-.285 2.479-.84.557-.567.831-1.24.831-2.05 0-.998-.28-1.692-.83-2.128-.578-.459-1.543-.702-2.9-.702h-2.66v-2.734l3.727-5.082.093-.096.336-.265z"
          fill={color}
        />
      )
      break

    case "4":
      color = colors.additional.redColor.WILD_WATERMELON
      numberSvg = (
        <path
          d="M38.026 39.712V44H33.21v-4.288H23V35.33L31.831 22h6.164v13.486H41v4.226h-2.974zM33 27h-.088L28 35h5v-8z"
          fill={color}
          fillRule="nonzero"
        />
      )
      break
  }

  return (
    <svg width={66} height={90}>
      <g
        transform="translate(0 1)"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
      >
        <circle stroke={color} cx={33} cy={33} r={22} />
        <path
          d="M36.499 62.255c-1.933-1.07-5.06-1.074-6.998 0l-2.581 1.43a3.937 3.937 0 01-5.395-1.584l-1.398-2.599c-1.047-1.945-3.676-3.638-5.887-3.783l-2.944-.193a3.937 3.937 0 01-3.682-4.249l.228-2.941c.171-2.203-1.125-5.048-2.907-6.366l-2.373-1.754a3.937 3.937 0 01-.8-5.565L3.545 32.3c1.334-1.76 1.783-4.855.996-6.927l-1.048-2.758a3.937 3.937 0 012.335-5.114L8.6 16.486c2.075-.76 4.125-3.12 4.583-5.288l.61-2.887a3.937 3.937 0 014.73-3.04l2.879.645c2.155.482 5.157-.395 6.714-1.972l2.074-2.099a3.937 3.937 0 015.622 0l2.074 2.1c1.552 1.57 4.552 2.455 6.714 1.97l2.88-.644a3.937 3.937 0 014.73 3.04l.61 2.887c.456 2.161 2.5 4.526 4.582 5.288l2.77 1.015a3.937 3.937 0 012.336 5.114l-1.048 2.758c-.784 2.065-.343 5.16.996 6.927l1.783 2.351a3.937 3.937 0 01-.8 5.565l-2.373 1.754c-1.777 1.313-3.079 4.156-2.907 6.366l.228 2.941a3.937 3.937 0 01-3.682 4.25l-2.944.192c-2.204.144-4.837 1.832-5.887 3.783l-1.398 2.599a3.937 3.937 0 01-5.395 1.584l-2.581-1.43z"
          stroke="#000"
        />
        <path stroke={color} d="M51 61v26L33 77 15 87V61" />
        {numberSvg}
      </g>
    </svg>
  )
}

export default Reward
