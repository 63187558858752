import * as React from 'react';
import Stripe from '../Stripe';
import Text from '../Text';
import './style.scss';

const process = [
  {
    title: 'Build your Team',
    list: [
      'We ask about your Development & Business challenges, required skillset, budget & timeframe.',
      'Together we brainstorm which Tech-Stack & Team Structure is best to build your Product and solve the challenges.',
      "We'll staff a team of skillful Developers, using all our experience in creating Digital Products.",
    ],
  },
  {
    title: 'Get Work Done',
    list: [
      'We adjust to your existing Development processes & tool-stack, or help to set up our proven Scrum / Kanban Workflow.',
      'Regular retrospectives of our IT staff augmentation services to ensure all parties are happy, the team is productive and efficient.',
      'Projects from 1 month to long-term, depending on your needs.',
    ],
  },
  {
    title: 'Iterate and Scale',
    list: [
      'Scale the team up and down within just 2 weeks.',
      'Add additional skillful resources or another specific expertise whenever you need.',
      "Found an In-House Developer? Cool, we'll help with a smooth transit of the project.",
    ],
  },
];

const TransparentProcess = () => {
  return (
    <div className={'transparent-process'}>
      <Text
        type="h2"
        color="dark"
        weight="bold"
        align="center"
        className={'transparent-process__title'}
      >
        Transparent execution process.
      </Text>
      <Text
        type="p"
        color="dark"
        weight="medium"
        align="center"
        className={'transparent-process__description h5'}
      >
        Hire a company for IT staff augmentation to build your Web & App
        Products from scratch or side-by-side as an extension of your existing
        team.
      </Text>
      <div className={'transparent-process__process'}>
        {process?.map((item, index) => {
          return (
            <div
              key={`transparent-process-${item.title}-${index}`}
              className={'transparent-process__process__step'}
            >
              <Stripe text={item.title} />
              <ul>
                {item.list.map((listItem, index) => (
                  <li
                    key={`transparent-process-item-list-${listItem}-${index}`}
                  >
                    <Text type="p" color="dark" weight="medium">
                      {listItem}
                    </Text>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
      <Text
        type="p"
        color="dark"
        align="center"
        className={'transparent-process__mini-text'}
      >
        Not happy with your team within the first 2 weeks? No invoice from us.
        Promise.
      </Text>
    </div>
  );
};

export default TransparentProcess;
