import * as React from 'react';
import { memo } from 'react';
import colors from '../../theme/colors';

interface Props {
  scaled?: boolean;
}

const JSBig: React.FC<Props> = ({ scaled = false, ...props }) => (
  <svg
    width={scaled ? '160vw' : 164}
    height={scaled ? '200.5vh' : 110}
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox={scaled ? '-140 -100 235 235' : '0 0 235 235'}
    xmlSpace="preserve"
    {...props}
  >
    <style />
    <switch>
      <g>
        <path
          fill={colors.additional.greyColor.TUNDORA}
          d="M212.67 17.33H22.33c-2.76 0-5 2.24-5 5v190.33c0 2.76 2.24 5 5 5h190.33c2.76 0 5-2.24 5-5V22.33c.01-2.76-2.23-5-4.99-5zm-5 190.34H27.33V27.33h180.33v180.34z"
        />
        <path
          fill={colors.additional.greyColor.TUNDORA}
          d="M158.32 197.1c24.57 0 40.44-13.73 40.44-34.98 0-20.67-12.03-30.01-31.45-38.39l-5.33-2.29c-9.71-4.23-10.14-5.74-10.14-8.48 0-2.64 1.96-4.22 5.24-4.22 3.7 0 6.29 1.06 9.39 6.64a5.004 5.004 0 007.09 1.78l16.64-10.75a4.995 4.995 0 001.64-6.65c-7.6-13.49-18.64-19.77-34.75-19.77-21.05 0-36.33 14.05-36.33 33.42 0 21.77 14.52 30.92 28.71 37.01l5.32 2.29c10.04 4.42 12.58 5.96 12.58 9.71 0 3.88-4.78 5.61-9.52 5.61-7.35 0-12.22-3.09-16.82-10.66a5 5 0 00-6.8-1.72l-17.38 10.16a5.001 5.001 0 00-1.95 6.56c7.9 15.7 23.73 24.73 43.42 24.73zm-23.08-30.48c4.32 5.68 11.11 11.38 22.61 11.38 11.49 0 19.52-6.42 19.52-15.61 0-10.68-8.6-14.47-18.59-18.87l-5.36-2.31c-16.51-7.08-22.65-14.62-22.65-27.82 0-14.01 10.58-23.42 26.33-23.42 10.73 0 17.99 3.26 23.5 10.73l-8.3 5.37c-3.99-5.04-8.83-7.33-15.2-7.33-8.83 0-15.24 5.98-15.24 14.22 0 9.83 7 13.66 16.16 17.66l5.35 2.3c19.23 8.3 25.4 15.39 25.4 29.2 0 18.42-15.73 24.98-30.44 24.98-14.18 0-25.4-5.36-32.11-15.2l9.02-5.28zM73.07 197.06c23.12 0 36.92-13.99 36.92-37.41V86.2c0-2.76-2.24-5-5-5H83.61c-2.76 0-5 2.24-5 5v73.15c0 8.52-2.72 8.52-6.47 8.52-4 0-5.76-2.02-9.4-8.51a4.954 4.954 0 00-3.09-2.39 5.008 5.008 0 00-3.87.57l-17.4 10.6a5 5 0 00-1.92 6.4c6.82 14.52 19.82 22.52 36.61 22.52zM56.66 168.7c3.09 4.67 7.55 9.17 15.47 9.17 10.93 0 16.47-6.23 16.47-18.52V91.2h11.38v68.44c0 22.66-14.64 27.41-26.92 27.41-11.43 0-19.96-4.32-25.41-12.86l9.01-5.49z"
        />
      </g>
    </switch>
  </svg>
);

export default memo(JSBig);
