import * as React from 'react';
import Text from '../Text';
import './style.scss';

export interface InfoItem {
  image: JSX.Element;
  title: string;
  list: string[];
  description: string;
}

interface Props {
  title: string;
  items: InfoItem[];
  className?: string;
}

const InfoDetailed: React.SFC<Props> = ({ title, items, className }) => {
  return (
    <div className={className}>
      <div className={'info-detailed'}>
        <Text
          type="h2"
          color="dark"
          weight="bold"
          align="center"
          className={'info-detailed__title'}
        >
          {title}
        </Text>
        <div className={'info-detailed__content'}>
          {items?.map((item: InfoItem, index: number) => {
            return (
              <div
                key={`info-detailed-${item.title}-${index}`}
                className={'info-detailed__content__item'}
              >
                <div className="info-detailed__content__item__image">
                  {item.image}
                </div>
                {/* <img src={item.image} /> */}
                <div className={'info-detailed__content__item__content'}>
                  <Text
                    type="h3"
                    color="primary"
                    weight="demi-bold"
                    className={'info-detailed__content__item__content__title'}
                  >
                    {item.title}
                  </Text>
                  <ul>
                    {item.list.map((listItem: string, index: number) => (
                      <li key={`info-detailed-list-${listItem}-${index}`}>
                        <Text type="p" color="dark" weight="medium">
                          {listItem}
                        </Text>
                      </li>
                    ))}
                  </ul>
                  <Text
                    type="p"
                    color="dark"
                    dim="60"
                    weight="medium"
                    className={
                      'info-detailed__content__item__content__description'
                    }
                  >
                    {item.description}
                  </Text>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default InfoDetailed;
