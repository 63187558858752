import React, { memo } from 'react';

function BLE(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={36}
      height={36}
      viewBox="0 0 70 70"
      {...props}
    >
      <style />
      <switch>
        <g>
          <path d="M40.29 35.29c0-2.92-2.37-5.29-5.29-5.29s-5.29 2.37-5.29 5.29 2.37 5.29 5.29 5.29 5.29-2.37 5.29-5.29z" />
          <path d="M31.22 45.67c-5.68-2.08-8.61-8.22-6.67-13.99 1.31-3.9 4.8-6.78 8.89-7.34 3.19-.44 6.41.51 8.81 2.61 2.41 2.1 3.8 5.14 3.8 8.33 0 4.62-2.92 8.79-7.27 10.38-.63.23-1.03.79-1.03 1.44v1.02a1.52 1.52 0 002 1.44c7.09-2.37 11.33-9.45 10.08-16.85-1.11-6.57-6.74-11.79-13.39-12.42-4.29-.41-8.4.96-11.58 3.85a15.097 15.097 0 00-4.93 11.13c0 6.49 4.14 12.23 10.3 14.28a1.52 1.52 0 002-1.44v-1.02c.01-.63-.39-1.19-1.01-1.42z" />
          <path d="M39.56 59.24c11.49-2.18 19.82-12.25 19.82-23.95C59.38 21.84 48.44 10.9 35 10.9S10.62 21.84 10.62 35.29c0 11.7 8.34 21.77 19.83 23.95a1.521 1.521 0 001.81-1.49v-.99c0-.72-.51-1.35-1.22-1.49-9.5-1.89-16.4-10.29-16.4-19.99 0-11.24 9.14-20.38 20.38-20.38S55.4 24.04 55.4 35.28c0 9.69-6.9 18.1-16.4 19.99-.71.14-1.22.77-1.22 1.49v.99c0 .45.2.88.55 1.17.32.28.78.4 1.23.32z" />
          <path d="M35 1.4C16.4 1.4 1.26 16.54 1.26 35.14c0 16.83 12.57 31.2 29.24 33.44.07.01.13.01.2.01.37 0 .72-.13 1-.38.33-.29.52-.7.52-1.14v-.97c0-.75-.56-1.4-1.31-1.5C16.29 62.57 5.26 49.91 5.26 35.14 5.26 18.75 18.6 5.41 35 5.41s29.74 13.34 29.74 29.74c0 14.75-11.01 27.41-25.61 29.45-.75.1-1.31.75-1.31 1.5v.98c0 .44.19.85.52 1.14.33.29.76.42 1.2.36 16.65-2.25 29.2-16.62 29.2-33.43C68.74 16.54 53.6 1.4 35 1.4z" />
        </g>
      </switch>
    </svg>
  );
}

export default memo(BLE);
