import * as React from 'react';
import { memo } from 'react';
import colors from '../../theme/colors';

interface Props {
  scaled?: boolean;
}

const MobileBig: React.FC<Props> = ({ scaled = false, ...props }) => (
  <svg
    width={scaled ? '160vw' : 164}
    height={scaled ? '200.5vh' : 130}
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox={scaled ? '-140 -100 235 235' : '0 0 235 235'}
    xmlSpace="preserve"
    {...props}
  >
    <style />
    <switch>
      <g>
        <path
          fill={colors.additional.greyColor.TUNDORA}
          d="M33.09 183.12h73.24c2.77 0 5.02-2.25 5.02-5.02v-32.19c0-2.77-2.25-5.02-5.02-5.02H33.09c-2.77 0-5.02 2.25-5.02 5.02v32.19c0 2.77 2.25 5.02 5.02 5.02zm5.03-10.04v-22.15h63.19v22.15H38.12zM33.09 128.59h73.24c2.77 0 5.02-2.25 5.02-5.02v-32.2c0-2.77-2.25-5.02-5.02-5.02H33.09c-2.77 0-5.02 2.25-5.02 5.02v32.19c0 2.77 2.25 5.03 5.02 5.03zm5.03-10.05V96.39h63.19v22.15H38.12z"
        />
        <path
          fill={colors.additional.greyColor.TUNDORA}
          d="M212.23 32.17H22.77c-7.41 0-13.44 6.03-13.44 13.44v152.24a4.98 4.98 0 004.98 4.98h206.38a4.98 4.98 0 004.98-4.98V45.61c-.01-7.41-6.03-13.44-13.44-13.44zm3.48 43.79v116.91h-85.62V75.96h85.62zM19.29 45.61c0-1.92 1.56-3.48 3.48-3.48h189.45c1.92 0 3.48 1.56 3.48 3.48V66H19.29V45.61zm100.84 30.35v116.91H19.29V75.96h100.84z"
        />
        <path
          fill={colors.additional.greyColor.TUNDORA}
          d="M73.84 49.09a4.98 4.98 0 10.001 9.961 4.98 4.98 0 00-.001-9.961zM54.81 49.09a4.98 4.98 0 10.001 9.961 4.98 4.98 0 00-.001-9.961zM35.78 49.09a4.98 4.98 0 10.001 9.961 4.98 4.98 0 00-.001-9.961zM142.19 152.08h23.26a4.98 4.98 0 000-9.96h-23.26a4.98 4.98 0 000 9.96zM137.21 164.02a4.98 4.98 0 004.98 4.98h47.36a4.98 4.98 0 000-9.96h-47.36a4.98 4.98 0 00-4.98 4.98zM177.28 175.95h-35.1a4.98 4.98 0 000 9.96h35.1a4.98 4.98 0 004.98-4.98c0-2.74-2.23-4.98-4.98-4.98zM174.16 125.69a5.028 5.028 0 004.64 3.13c.61 0 1.22-.11 1.82-.35a4.997 4.997 0 002.79-6.47l-11.77-29.6a4.916 4.916 0 00-2.66-2.73 4.936 4.936 0 00-3.81-.05 4.884 4.884 0 00-2.73 2.66 4.89 4.89 0 00-.05 3.81l11.77 29.6zM145.78 124.91a4.96 4.96 0 003.04 2.32c.43.12.86.17 1.29.17.87 0 1.72-.23 2.48-.68 2.36-1.39 3.18-4.44 1.81-6.8l-6.43-11.1 6.39-10.59c.69-1.14.89-2.48.57-3.77a4.958 4.958 0 00-2.26-3.07c-2.35-1.41-5.42-.66-6.83 1.69l-7.91 13.11c-.94 1.56-.95 3.5-.04 5.07l7.89 13.65zM197.83 108.82l-6.43 11.1c-.67 1.15-.85 2.5-.5 3.79a4.958 4.958 0 002.32 3.02c.79.46 1.65.67 2.49.67 1.7 0 3.35-.88 4.3-2.48l7.91-13.66c.91-1.57.89-3.51-.04-5.07l-7.91-13.11a4.958 4.958 0 00-3.07-2.26 4.937 4.937 0 00-3.77.57 4.978 4.978 0 00-1.69 6.83l6.39 10.6z"
        />
      </g>
    </switch>
  </svg>
);

export default memo(MobileBig);
