import * as React from 'react';
import './style.scss';
import Text from '../Text';

interface Pro {
  image: JSX.Element;
  title: string;
  description: string;
}

export interface Props {
  tech: string;
  pros: Pro[];
}

const WhyTech: React.SFC<Props> = ({ tech, pros }) => {
  return (
    <div className={'why-tech'}>
      <Text
        type="h3"
        color="dark"
        weight="bold"
        align="center"
        className={'why-tech__title'}
      >{`Why ${tech} Development Services?`}</Text>
      <div className={'why-tech__pros'}>
        {pros?.map((pro, index) => {
          return (
            <div
              key={`why-tech-${pro.title}-${index}`}
              className={'why-tech__pros__pro'}
            >
              <div className={'why-tech__pros__pro__reward'}>{pro.image}</div>
              <Text
                type="h5"
                color="dark"
                weight="bold"
                align="center"
                className={'why-tech__pros__pro__title'}
              >
                {pro.title}
              </Text>
              <Text
                type="h6"
                color="dark"
                dim="60"
                weight="medium"
                align="center"
                className={'why-tech__pros__pro__description'}
              >
                {pro.description}
              </Text>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WhyTech;
