import classNames from 'classnames';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import * as React from 'react';
import useStripe from '../../hooks/useStripe';
import Text from '../Text';
import './style.scss';

interface Props {
  text: string;
  className?: string;
}

const Stripe: React.SFC<Props> = ({ text, className }) => {
  const { tail, head, body } = useStripe();

  return (
    <div className={classNames('stripe', className)}>
      <GatsbyImage
        image={tail!}
        imgStyle={{
          objectFit: 'contain',
        }}
        alt=""
      />
      <BackgroundImage
        Tag="div"
        style={{ display: 'flex' }}
        className={'stripe__body'}
        {...convertToBgImage(body)}
      >
        <Text
          type="h3"
          color="secondary"
          weight="demi-bold"
          align="center"
          className="stripe__text"
        >
          {text}
        </Text>
      </BackgroundImage>
      <GatsbyImage image={head!} alt="" />
    </div>
  );
};

export default Stripe;
