import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Tab } from '../../models/Tab';
import Text from '../Text';
import TabLink from './TabLink';
import ChevronRight from '../icons/ChevronRight';
import ServiceTitle from '../ServiceTitle';

interface Props {
  tabs: Tab[];
  header?: string;
  description?: string;
  colorMode?: 'dark' | 'light';
  variant?: 'primary' | 'secondary';
  backgroundMode?: 'darkBG' | 'lightBG' | 'transparentBG';
  showScrollButton?: boolean;
}

const SCROLL_STEP_WIDTH = 150;

const LinkOutreach: React.FC<Props> = ({
  tabs,
  header,
  description,
  colorMode = 'dark',
  variant,
  backgroundMode,
  showScrollButton = false,
}) => {
  // make default value undefined, so that google cache shows all the options opened
  const [activeIndex, setActiveIndex] = useState<number>();

  const tabsListRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    setActiveIndex(0);
  }, []);

  const renderTabLink = useCallback<(item: Tab, index: number) => JSX.Element>(
    (item, index) => (
      <TabLink
        onChange={setActiveIndex}
        colorMode={colorMode}
        isActive={index === activeIndex}
        text={item.header}
        index={index}
        isLegacy={false}
      />
    ),
    [activeIndex, colorMode]
  );

  const handleTabsScroll = useCallback(() => {
    const currentPosition = tabsListRef.current?.scrollLeft ?? 0;

    tabsListRef.current?.scrollTo({
      left: currentPosition + SCROLL_STEP_WIDTH,
      behavior: 'smooth',
    });
  }, []);

  return (
    <section className={classNames('link-outreach', variant, backgroundMode)}>
      <div className="link-outreach__container">
        {header && (
          <ServiceTitle color={colorMode} className="link-outreach__title">
            {header}
          </ServiceTitle>
        )}
        {description && (
          <Text
            type="p"
            color={colorMode}
            className={'link-outreach__description'}
          >
            {description}
          </Text>
        )}
        <div className={'link-outreach__tabs'}>
          <ul className={'link-outreach__tabs__list'} ref={tabsListRef}>
            {tabs?.map(renderTabLink)}
          </ul>

          {showScrollButton && (
            <button
              onClick={handleTabsScroll}
              className={'link-outreach__tabs__button'}
            >
              <ChevronRight variant="light" />
            </button>
          )}
        </div>
        <div className={'link-outreach__content'}>
          {tabs?.map((tab, index) => (
            <div
              className={classNames('link-outreach__content__list', {
                'link-outreach__content__list--active':
                  activeIndex === undefined ? true : index === activeIndex,
              })}
              key={index}
            >
              <div className={'link-outreach__content__list-item'}>
                {typeof tab.description === 'string' ? (
                  <Text
                    type="p"
                    color={colorMode}
                    className={'link-outreach__content__description'}
                  >
                    {tab.description}
                  </Text>
                ) : (
                  <div className={'link-outreach__content__description'}>
                    {tab.description}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default LinkOutreach;
