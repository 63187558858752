import * as React from 'react';
import { SVGProps, memo } from 'react';
import { IconColor } from '../../utils/colorTypes';

interface Props extends SVGProps<SVGSVGElement> {
  variant?: IconColor;
  pathClassName?: string;
}

const colors: Record<IconColor, string> = {
  light: '#ffffff',
  dark: '#13171e',
};

const ChevronRight: React.FC<Props> = ({
  pathClassName,
  variant = 'dark',
  ...props
}) => (
  <svg
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 1L7 7L0.999999 13"
      stroke={colors[variant]}
      strokeWidth="1.5"
      className={pathClassName}
    />
  </svg>
);

export default memo(ChevronRight);
