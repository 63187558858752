import React, { memo } from 'react';

function DevTools(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={36}
      height={36}
      viewBox="0 0 70 70"
      {...props}
    >
      <style />
      <switch>
        <g>
          <path d="M10.79 54.79h19.38c1 0 1.82-.82 1.82-1.82V28.28c0-1-.82-1.82-1.82-1.82H10.79c-1 0-1.82.82-1.82 1.82v24.69c0 1 .81 1.82 1.82 1.82zm1.82-3.64V30.1h15.73v21.05H12.61z" />
          <path d="M63.79 8.62H6.21c-2.5 0-4.53 2.03-4.53 4.53v46.27c0 1.08.88 1.96 1.96 1.96h62.72c1.08 0 1.96-.88 1.96-1.96V13.15c0-2.5-2.03-4.53-4.53-4.53zm.61 14.21v34.63H39.28V22.83H64.4zm0-9.68v5.75H5.6v-5.75c0-.33.27-.61.61-.61h57.58c.33 0 .61.28.61.61zm-29.05 9.68v34.63H5.6V22.83h29.75z" />
          <path d="M12.02 13.76c-1.08 0-1.96.88-1.96 1.96s.88 1.96 1.96 1.96 1.96-.88 1.96-1.96-.88-1.96-1.96-1.96zM44.46 48.98h7.07c1.08 0 1.96-.88 1.96-1.96s-.88-1.96-1.96-1.96h-7.07c-1.08 0-1.96.88-1.96 1.96s.88 1.96 1.96 1.96zM44.46 54.8h14.4c1.08 0 1.96-.88 1.96-1.96s-.88-1.96-1.96-1.96h-14.4c-1.08 0-1.96.88-1.96 1.96s.88 1.96 1.96 1.96zM45.16 37.48c.25.45.69.78 1.2.92.17.04.34.07.51.07.34 0 .68-.09.98-.27.93-.55 1.25-1.75.71-2.68l-1.82-3.14 1.8-2.99c.27-.45.35-.98.22-1.49s-.44-.94-.89-1.21a1.97 1.97 0 00-2.7.67l-2.4 3.99c-.37.61-.38 1.38-.02 2l2.41 4.13zM56.81 32.37l-1.82 3.14c-.26.46-.33.99-.2 1.49.13.51.46.93.92 1.19.3.17.64.26.98.26.7 0 1.33-.36 1.7-.98l2.41-4.15c.36-.62.35-1.38-.02-2l-2.4-3.99c-.27-.45-.7-.77-1.21-.89a1.959 1.959 0 00-2.16 2.91l1.8 3.02z" />
        </g>
      </switch>
    </svg>
  );
}

export default memo(DevTools);
