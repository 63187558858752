import { HeadProps, PageProps, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import BuiltByStormotion from '../components/BuiltByStormotion';
import CardBoard from '../components/CardBoard';
import Comments from '../components/Comments';
import Companies from '../components/Companies';
import DigitalProductDevelopment from '../components/DigitalProductDevelopment';
import Footer from '../components/Footer';
import HaveIdea from '../components/HaveIdea';
import Header from '../components/Header';
import AI from '../components/icons/AI';
import AndroidBig from '../components/icons/AndroidBig';
import BLE from '../components/icons/BLE';
import DevTools from '../components/icons/DevTools';
import Firebase from '../components/icons/Firebase';
import Graphics from '../components/icons/Graphics';
import GraphQL from '../components/icons/GraphQL';
import IOSBig from '../components/icons/IOSBig';
import JSBig from '../components/icons/JSBig';
import Kotline from '../components/icons/Kotline';
import MobileBig from '../components/icons/MobileBig';
import NavigationIcon from '../components/icons/NavigationIcon';
import NodeJs from '../components/icons/NodeJs';
import Ownership from '../components/icons/Ownership';
import PartnerInCrime from '../components/icons/PartnerInCrime';
import Payments from '../components/icons/Payments';
import ReactIcon from '../components/icons/React';
import ReactBig from '../components/icons/ReactBig';
import Reward from '../components/icons/Reward';
import Swift from '../components/icons/Swift';
import TypeScript from '../components/icons/TypeScript';
import WebBig from '../components/icons/WebBig';
import WellEstablishedProcess from '../components/icons/WellEstablishedProcess';
import InfoDetailed from '../components/InfoDetailed';
import LatestArticlesList from '../components/LatestArticlesList';
import Layout from '../components/layout';
import LinkOutreach from '../components/LinkOutreach';
import ListOfIcons from '../components/ListOfIcons';
import ListOfInlineIcons from '../components/ListOfInlineIcons';
import Navigation from '../components/Navigation';
import ProductDev from '../components/ProductDev';
import Questions from '../components/Questions';
import SEO from '../components/seo';
import ServiceHeader from '../components/ServiceHeader';
import Special from '../components/Special';
import StaffAugmentation from '../components/StaffAugmentation';
import TechHeader from '../components/TechHeader';
import TransparentProcess from '../components/TransparentProcess';
import WhyTech from '../components/WhyTech';
import useApps from '../hooks/useApps';
import useServices from '../hooks/useServices';
import './style.scss';

const whyTech = {
  tech: 'React Native',
  pros: [
    {
      image: <Reward number="1" />,
      title: 'Really Native',
      description:
        'Deliver React Native apps that look and feel native. Even the pickiest Android and iOS devotees won’t be able to tell the difference.',
    },
    {
      image: <Reward number="2" />,
      title: 'Reusable',
      description:
        'Build once, use many. JS-codebase used to develop React Native apps can be reused to build apps on all platforms: iOS, Android, and Web.',
    },
    {
      image: <Reward number="3" />,
      title: 'Cost-Efficient',
      description:
        'Due to the shared codebase, you don’t need to hire separate native iOS & Android teams. You just need one good React Native Development company – simpler management, faster delivery, fewer costs.',
    },
    {
      image: <Reward number="4" />,
      title: 'Ideal to iterate',
      description:
        'Using React Native, businesses and startups can deliver their products in a shorter time due to faster coding and instant over-the-air updates.',
    },
  ],
};

const icons = [
  <Firebase />,
  <NodeJs />,
  <Kotline />,
  <ReactIcon />,
  <Swift />,
  <TypeScript />,
  <GraphQL />,
];
const HEADER_ICONS: Record<any, any> = {
  MobileBig,
  PartnerInCrime,
  WellEstablishedProcess,
  Ownership,
  IOSBig,
  AndroidBig,
  WebBig,
  ReactBig,
  JSBig,
  BLE,
  Payments,
  DevTools,
  NavigationIcon,
  Graphics,
  AI,
};

type DataProps = any;

function ListTemplate({ data }: PageProps<DataProps>) {
  const { mdx } = data;
  const {
    frontmatter: {
      questions,
      contactUs,
      specialTitle,
      showFooter,
      showReactNative,
      showRaised,
      showDrivenBy,
      showTransparent,
      header,
      zoneGeniusTitle,
      zoneGenius,
      tabs,
      traits,
      techStackDescription,
      techStack,
      service,
      tabsHeader,
      tabsDescription,
      development,
    },
  } = mdx;
  const apps = useApps(true);
  const services = useServices();

  const infoItemsWithImages = useMemo(
    () =>
      service?.items?.map((item: any) => ({
        ...item,
        // @ts-ignore
        image: services[item.image] ? (
          <GatsbyImage
            // @ts-ignore
            image={services[item.image]}
            style={{ flexShrink: 0 }}
            alt={item.title}
          />
        ) : (
          <div />
        ),
      })),
    [service, services]
  );
  // const traitsWithIcons = useMemo(
  //   () =>
  //     traits?.map((item: any) => ({
  //       ...item,
  //       image: HEADER_ICONS[item.icon],
  //     })),
  //   [traits]
  // );

  const stackWithIcons = useMemo(
    () =>
      techStack?.map((item: any) => {
        const Image = HEADER_ICONS[item.icon];
        return {
          ...item,
          image: <Image width={36} height={36} />,
        };
      }),
    [techStack]
  );
  const geniusWithIcons = useMemo(
    () =>
      zoneGenius?.map((item: any) => {
        const Image = HEADER_ICONS[item.icon];
        return {
          ...item,
          image: <Image />,
        };
      }),
    [zoneGenius]
  );

  return (
    <Layout>
      <Navigation headerBackgroundColor="light" />
      <Header light>
        {header.icon ? (
          <TechHeader
            Icon={HEADER_ICONS[header.icon]}
            title={header.title}
            subtitle={header.subtitle}
            description={header.description}
          />
        ) : (
          <ServiceHeader {...header} />
        )}
      </Header>
      {showReactNative && <WhyTech {...whyTech} />}
      {service && (
        <InfoDetailed title={service.title} items={infoItemsWithImages} />
      )}
      {zoneGenius && (
        <CardBoard
          title={zoneGeniusTitle || 'Our Zone of Genius.'}
          isUnderHeader
          data={geniusWithIcons}
          className={'card-board'}
        />
      )}
      {tabs && (
        <LinkOutreach
          tabs={tabs}
          header={tabsHeader}
          description={tabsDescription}
        />
      )}
      {techStack && (
        <ListOfInlineIcons
          icons={stackWithIcons}
          title="Tech Stack"
          description={techStackDescription}
        />
      )}
      {development.productDevelopment && development.staffAugmentation ? (
        <ProductDev {...development} />
      ) : development.productDevelopment ? (
        <DigitalProductDevelopment {...development.productDevelopment} />
      ) : (
        <StaffAugmentation
          {...development.staffAugmentation}
          title="Staff Augmentation"
        />
      )}
      <Comments title={'Our Clients Trust Us'} />
      <HaveIdea {...contactUs} />
      <BuiltByStormotion apps={apps} />
      <Special
        traits={traits}
        title={specialTitle || 'Stormotion, why are you so special?'}
      />
      {showTransparent && <TransparentProcess />}
      {showRaised && <Companies />}
      {showDrivenBy && (
        <ListOfIcons
          icons={icons}
          title="Driven by amazing Technologies"
          className="drivenBy"
        />
      )}
      <Questions questions={questions} />
      <LatestArticlesList />
      {showFooter && <Footer noFooterCard={false} />}
    </Layout>
  );
}

export const pageQuery = graphql`
  query($id: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        description
        header {
          title
          subtitle
          description
          icon
        }
        zoneGeniusTitle
        zoneGenius {
          title
          description
          icon
          href
        }
        tabs {
          header
          title
          description
        }
        techStackDescription
        techStack {
          icon
          name
          technologies
        }
        specialTitle
        traits {
          icon {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
            extension
            publicURL
          }
          title
          description
        }
        contactUs {
          title
          buttonTitle
        }
        questions {
          question
          answer
        }
        development {
          productDevelopment {
            header
            title
            description
            steps
          }
          staffAugmentation {
            header
            patterns {
              title
              description
              caption
            }
          }
        }
        service {
          title
          items {
            image
            title
            list
            description
          }
        }
        tabsHeader
        tabsDescription
        showFooter
        showReactNative
        showDrivenBy
        showTransparent
        showRaised
      }
    }
  }
`;

export const Head = ({ data }: HeadProps<DataProps>) => {
  const { mdx } = data;
  const {
    frontmatter: { title, description, questions },
  } = mdx;

  return <SEO title={title} description={description} questions={questions} />;
};

export default ListTemplate;
