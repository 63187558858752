import * as React from 'react';
import './style.scss';
import Text from '../Text';
import classNames from 'classnames';

interface Icon {
  name: string;
  technologies: string[];
  image: JSX.Element;
}

export interface Props {
  icons: Icon[];
  title: string;
  description?: string;
  light?: boolean;
  dark?: boolean;
  atTheBottom?: boolean;
}

const ListOfInlineIcons: React.SFC<Props> = ({
  icons,
  title,
  description,
  atTheBottom = false,
}) => {
  return (
    <div
      className={classNames('list-of-inline-icons', {
        ['list-of-inline-icons--bottom-component']: atTheBottom,
      })}
    >
      <Text
        type="h3"
        color="dark"
        weight="bold"
        align="center"
        className={'list-of-inline-icons__title'}
      >
        {title}
      </Text>
      {description && (
        <Text
          type="h4"
          color="dark"
          align="center"
          className={'list-of-inline-icons__description'}
        >
          {description}
        </Text>
      )}
      <div className={'list-of-inline-icons__icons'}>
        {icons?.map((tech, index) => (
          <div
            key={`list-of-inline-icons-${tech.name}-${index}`}
            className={classNames('list-of-inline-icons__icons__icon', [
              'list-of-inline-icons__icons__icon--light',
            ])}
          >
            <div
              className={classNames('list-of-inline-icons__icons__icon__logo', [
                'list-of-inline-icons__icons__icon__logo--light',
              ])}
            >
              {tech.image}
            </div>
            <div className={'list-of-inline-icons__content'}>
              <Text
                type="h6"
                color="dark"
                weight="demi-bold"
                align="left"
                className={'list-of-inline-icons__icons__icon__title'}
              >
                {tech.name}
              </Text>
              <ul className={'list-of-inline-icons__content-list'}>
                {tech.technologies?.map(technology => (
                  <li className={'list-of-inline-icons__content-list-item'}>
                    {technology}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListOfInlineIcons;
