import React, { memo } from 'react';

function Graphics(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={36}
      height={36}
      viewBox="0 0 70 70"
      {...props}
    >
      <style />
      <switch>
        <g>
          <path d="M61.42 10.65H18.04c-3.82 0-7.41 1.49-10.1 4.19-2.69 2.7-4.16 6.28-4.14 10.08v29.65c0 2.64 2.15 4.79 4.79 4.79h44.34c7.33 0 13.29-5.96 13.29-13.29V15.44c-.01-2.64-2.16-4.79-4.8-4.79zm.79 4.79v30.63c0 5.12-4.17 9.29-9.29 9.29H8.58c-.44 0-.79-.35-.79-.79V24.91a10.17 10.17 0 012.98-7.25 10.18 10.18 0 017.27-3.02h43.38c.44 0 .79.36.79.8z" />
          <path d="M49 31.43L29.8 19.9c-1.3-.78-2.87-.8-4.19-.05-1.32.75-2.11 2.1-2.11 3.62v23.07c0 1.52.79 2.87 2.11 3.62.63.36 1.34.55 2.05.55.75 0 1.49-.21 2.14-.6L49 38.57A4.137 4.137 0 0051.02 35c0-1.47-.75-2.81-2.02-3.57zM27.34 22.9l.4.42 19.2 11.54c.03.02.08.05.08.14 0 .09-.05.13-.08.14l-19.2 11.54c-.03.02-.09.05-.17 0-.08-.04-.08-.1-.08-.14V23.46c0-.05.01-.1.08-.14l-.23-.42z" />
        </g>
      </switch>
    </svg>
  );
}

export default memo(Graphics);
