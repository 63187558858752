import React from 'react';
import { Tab } from '../../models/Tab';
import LinkOutreachLegacy from './LinkOutreachLegacy';
import LinkOutreachUpdated from './LinkOutreachUpdated';
import './style.scss';

interface Props {
  tabs: Tab[];
  header?: string;
  description?: string;
  colorMode?: 'dark' | 'light';
  variant?: 'primary' | 'secondary';
  backgroundMode?: 'darkBG' | 'lightBG' | 'transparentBG';
  isLegacy?: boolean;
  showScrollButton?: boolean;
}

const LinkOutreach: React.FC<Props> = ({
  colorMode = 'dark',
  isLegacy = true,
  ...props
}) => {
  return isLegacy ? (
    <LinkOutreachLegacy colorMode={colorMode} {...props} />
  ) : (
    <LinkOutreachUpdated colorMode={colorMode} {...props} />
  );
};

export default LinkOutreach;
